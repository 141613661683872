import React, { Component } from 'react';

export default class svgFormatter extends Component {
	render() {
		return (
			<div>
                
			</div>
		);
	}
}
