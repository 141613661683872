import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import styles from '../App.module.scss';
import ComponentViewer from '../components/myComponents/componentViewer/ComponentViewer';
import HomePage from '../components/home/HomePage';
import * as routes from '../constants/routes';
import SvgFormatter from '../components/svg/formatter/SvgFormatter';
import SvgPlayground from '../components//svg/playground/SvgPlayground';
import WordsMatter from '../components/words/matter/WordsMatter';

export class Routes extends Component {
	render() {
		return (
			<div className={ styles['content'] }>
				<Route path={ routes.components } exact component={ ComponentViewer } />

				<Route path={ routes.svgFormatter } exact component={ SvgFormatter } />
				<Route path={ routes.svgPlayground } exact component={ SvgPlayground } />

				<Route path={ routes.wordsMatter } exact component={ WordsMatter } />
				<Route path={ routes.home } exact component={ HomePage } />
				{ /* <Redirect from='/*' to={routes.home} /> */ }
			</div>
		);
	}
}

export default Routes;
