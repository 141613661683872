import React, { Component } from 'react';
import styles from './WordsMatter.module.scss';
import { calculateWordScore, removeNonLettersFromWord, getWordsWithSameScore } from '../../../utils/WordsMatterHelper';

export default class WordsMatter extends Component {
	constructor( props ) {
		super( props );
    
		this.state = {
			word: '',
			score: ''
		};
	}
    
	render() {
		return (
			<div className={ styles['words-matter'] }>
				<div className={ styles['input-container'] }>
					<input className={ styles['word'] } 
						onChange={ ( e )=> this.setState({ word: removeNonLettersFromWord( e.target.value ), score: calculateWordScore( removeNonLettersFromWord( e.target.value ) ) }) }
						value={ this.state.word }
					/>
					<div className={ styles['score'] }>
						{ this.state.score }
					</div>
				</div>
				<div className={ styles['output-container'] }>
					{ getWordsWithSameScore() }
				</div>
			</div>
		);
	}
}
