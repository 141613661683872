export const calculateWordScore = (word) => {
	const cleanedWord = word.toLowerCase();
	let sum = 0;
	cleanedWord.split('').forEach((character) => {
		sum += character.charCodeAt(0) - 96;
	});
	return sum;
};

export const removeNonLettersFromWord = (word) => {
	return word.replace(new RegExp('[^a-zA-Z]'), '');
};

export const getWordsWithSameScore = (score) => {
	return 'soon';
};
