import React from 'react';
import styles from './waterdrop.module.scss';

function WaterDrop() {
	return (
		<div className={ styles['waterdrop-container'] }>
			<div></div>
		</div>
	);
}

export default WaterDrop;
